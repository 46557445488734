'use client';
import type { Locale } from '@/i18n/i18n';
import { Link } from '@/i18n/routing';
import { useEffect, useState } from 'react';

type GtagParams = {
  ad_user_data: 'granted' | 'denied';
  ad_personalization: 'granted' | 'denied';
  ad_storage: 'granted' | 'denied';
  analytics_storage: 'granted' | 'denied';
  wait_for_update: number;
};

const denied: GtagParams = {
  ad_user_data: 'denied',
  ad_personalization: 'denied',
  ad_storage: 'denied',
  analytics_storage: 'denied',
  wait_for_update: 500,
};

const granted: GtagParams = {
  ad_user_data: 'granted',
  ad_personalization: 'granted',
  ad_storage: 'granted',
  analytics_storage: 'granted',
  wait_for_update: 500,
};

export const CookiesClient = ({
  locale,
  content,
  accept,
  decline,
  more,
}: {
  locale: Locale;
  content: string | TrustedHTML;

  accept: string | null | undefined;
  decline: string | null | undefined;
  more: string | null | undefined;
}) => {
  const [open, setOpen] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(() =>
    getLocalStorage('cookie_consent', false),
  );

  useEffect(() => {
    const savedConsent = getLocalStorage('cookie_consent', undefined);
    const newValue = savedConsent ? granted : denied;

    if (typeof savedConsent === 'undefined' || savedConsent === 'false')
      return setOpen(true);

    if (typeof window !== 'undefined')
      window?.gtag?.('consent', 'update', newValue);
  }, [cookieConsent]);

  if (!open || cookieConsent) return null;

  return (
    <>
      <div className="fixed inset-x-0 bottom-0 z-50 flex flex-col items-center justify-center bg-gray-800 text-white p-4 sm:p-6 lg:p-8">
        <p className="text-lg md:text-xl text-center mb-4"></p>
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />

        <div className="flex flex-col md:flex-row gap-2">
          <button
            onClick={() => {
              setOpen(false);
              setCookieConsent(true);
              setLocalStorage('cookie_consent', true);
            }}
            className="btn btn-md btn-default"
          >
            {accept}
          </button>
          <button
            onClick={() => {
              setOpen(false);
              setCookieConsent(false);
              setLocalStorage('cookie_consent', false);
            }}
            className="btn btn-md btn-warning"
          >
            {decline}
          </button>
        </div>
        <Link
          target="_blank"
          href={`/privacy-policy`}
          className="mt-2 text-sm text-gray-300 underline"
        >
          {more}
        </Link>
      </div>
    </>
  );
};

export function getLocalStorage(key: string, defaultValue: any) {
  if (typeof window === 'undefined') return defaultValue;
  const stickyValue = localStorage.getItem(key);

  return stickyValue !== null && stickyValue !== 'undefined'
    ? JSON.parse(stickyValue)
    : defaultValue;
}

export function setLocalStorage(key: string, value: any) {
  if (typeof window === 'undefined') return;

  localStorage.setItem(key, JSON.stringify(value));
}
